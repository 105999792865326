import { defineAsyncComponent, createApp } from "vue";

import { apolloProvider } from 'src/graphql/apollo-client';
import i18n from 'src/i18n';
import VueTimeago3 from 'vue-timeago3';
import eventBus from 'src/eventBus';

// Sub-templates
const MentorFilter = defineAsyncComponent(() => import(/* webpackChunkName: "template-mentor-filter" */ 'src/views/forms/mentor-filter'));
const MentorHeroActions = defineAsyncComponent(() => import(/* webpackChunkName: "template-mentor-hero-actions" */ 'src/views/forms/mentor-hero-actions'));
const MentorshipPaymentModal = defineAsyncComponent(() => import(/* webpackChunkName: "template-mentorship-payment-modal" */ 'src/views/profile/parts/mentorship-payment-modal'));
const ManageProfile = defineAsyncComponent(() => import(/* webpackChunkName: "template-manage-profile" */ 'src/views/profile/manage'));

// Pages
const Onboarding = defineAsyncComponent(() => import(/* webpackChunkName: "route-onboarding" */ 'src/views/onboarding/onboarding'));
const EditProfile = defineAsyncComponent(() => import(/* webpackChunkName: "route-edit-profile" */ 'src/views/profile/edit'));
const MentorRequest = defineAsyncComponent(() => import(/* webpackChunkName: "route-mentor-request" */ 'src/views/mentor-request/mentor-request'));
const MentorRequestIndex = defineAsyncComponent(() => import(/* webpackChunkName: "route-request-index" */ 'src/views/mentor-request/mentor-request-index'));
const SingleMentorRequest = defineAsyncComponent(() => import(/* webpackChunkName: "route-single-request" */ 'src/views/mentor-request/single-mentor-request'));
const ProfileSettings = defineAsyncComponent(() => import(/* webpackChunkName: "route-profile-settings" */ 'src/views/profile/settings'));
const ContactModal = defineAsyncComponent(() => import(/* webpackChunkName: "route-contact-modal" */ 'src/views/common/contact-modal'));
const StatusModal = defineAsyncComponent(() => import(/* webpackChunkName: "route-status-modal" */ 'src/views/common/status-modal'));
const CloseRequest = defineAsyncComponent(() => import(/* webpackChunkName: "route-close-request" */ 'src/views/mentor-request/close-request'));
const ActivityFeed = defineAsyncComponent(() => import(/* webpackChunkName: "route-activity-feed" */ 'src/views/mentor/activity-feed'));
const MentorshipAgreementForm = defineAsyncComponent(() => import(/* webpackChunkName: "route-agreement-form" */ 'src/views/forms/mentorship-agreement-form'));

// This imports all common, loader components and directives globally.
import { ClickOutside } from "src/directives/ClickOutside";
import Alert from 'src/views/common/alert';
import Flash from 'src/views/common/flash';
import Icon from 'src/views/common/icon';
import Modal from 'src/views/common/modal';
import Tag from 'src/views/common/tag';
import Timeago from "src/views/common/timeago";
import LoaderCluster from "src/views/loaders/loader-cluster";
import LoaderClusterItem from "src/views/loaders/loader-cluster-item";

let mentorsApps = document.getElementsByClassName('vc4a-mentors-app');
let apps = [];
if( mentorsApps.length ) {
    for( let i=0; i<mentorsApps.length; i++ ) {
        let mentorApp = mentorsApps[i];
        apps[i] = createApp({
            components: {
                MentorFilter,
                MentorHeroActions,
                MentorshipPaymentModal,
                Onboarding,
                EditProfile,
                MentorRequest,
                ManageProfile,
                SingleMentorRequest,
                MentorRequestIndex,
                ProfileSettings,
                ContactModal,
                StatusModal,
                CloseRequest,
                ActivityFeed,
                MentorshipAgreementForm
            }
        })
            .use(i18n)
            .use(apolloProvider)
            .use(VueTimeago3, {
                name: 'vue-timeago-3'
            })

            //Common Components and loaders
            .component('Alert', Alert)
            .component('Flash', Flash)
            .component('Icon', Icon)
            .component('Modal', Modal)
            .component('Tag', Tag)
            .component('Timeago', Timeago)
            .component('LoaderCluster', LoaderCluster)
            .component('LoaderClusterItem', LoaderClusterItem)

            // Vue directives
            .directive('click-outside', ClickOutside);

        apps[i].config.globalProperties.$emitter = eventBus;

        apps[i].mount('#' + mentorApp.id);
    }
}
