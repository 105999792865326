<template>
    <vue-timeago-3
        :datetime="datetime"
        :locale="locales[locale]"
        :key="'timeago-' + randomKey"
    />
</template>

<script>
import i18n from "src/i18n";
import { enUS, es, fr } from 'date-fns/locale';
export default {
    name: "timeago",
    props: {
        datetime: {
            type: [String, Number, Date]
        },
        locale: {
            type: String,
            default: i18n.global.locale.value
        }
    },
    data(){
        return {
            randomKey: Math.random() // Use random key to force rerender of vue-timeago3. this.$forceUpdate() didn't seem to work
        }
    },
    computed: {
        locales() {
            return {
                en: enUS,
                es: es,
                fr: fr
            }
        }
    },
    methods: {
        rerenderComponent() {
            
            // Generate random key to force component to re-render
            this.randomKey = Math.random();
        }
    },
    watch: {
        locale() {
            this.rerenderComponent(); // Re-render timeago component whenever the locale changes
        }
    }
}
</script>
